@import 'variable';
@import 'mixin';

/* ======================================================================
 p_top
====================================================================== */
#p_top {
  overflow: hidden;
  .l_content {
    padding: 60px 0 80px;
    @include sp {
      padding: 20px 0 40px;
    }
  }
  .l_main {
    width: auto;

  }
  .l_bg_link {
    height: 385px;
    @include sp {
      height: auto;
    }
    .box {
      @include sp {
        margin: 20px 0 0;
      };
      &.box01 {
        @include sp {
          background: url(../img/p_top/sp/bg01.png);
          background-size: cover;
        }
        &:before {
          background: url(../img/p_top/bg01.png);
          background-size: cover;
          background-position: center center;
          @include sp {
            background-image: none;
            background: #222;
          }
        }
      }
      &.box02 {
        @include sp {
          background: url(../img/p_top/sp/bg02.png);
          background-size: cover;
        }
        &:before {
          background: url(../img/p_top/bg02.png);
          background-size: cover;
          background-position: center center;
          @include sp {
            background-image: none;
            background: #222;
          }
        }
      }
    }
  }
}

.ttlwrap {
  width: 1100px;
  margin: 100px auto 40px;
  @include sp {
    width: 100%;
    margin: 80px 0 0;
    padding: 0 4.6875%;
    position: relative;
    &:before {
      content: '+';
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;
      color: #B7B7B7;
    }
  }
  .c_ttl_1 {
    @include sp {
      font-size: 28px!important;
      &:before {
        top: 15px;
      }
    }
  }
  .u_txt_1 {
    margin: 30px 0 0;
    @include sp {
      margin: 10px 0 0;
      font-size: 14px;
    }
  }
}

.graph {
  width: 1100px;
  margin: 0 auto 80px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-end;
  @include sp {
    margin: 20px 0 30px;
    width: 100%;
    padding: 0 4.6875%;
  }
}

.sec {

  >.inner {
    width: 1100px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
    .flex {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }
  }
  &.sec1 {
    .txtwrap {
      width: 540px;
      padding: 40px 70px 0 0;
      @include sp {
        width: 100%;
        padding: 40px 4.6875% 0;
      }
      img {
        @include sp {
          max-width: 100%;
          margin: 0 0 15px;
        }
      }
      .c_ttl_1 {
        margin: 0 0 30px;
        @include sp {
          font-size: 28px!important;
          margin: 0 0 15px;
        }
      }
    }
    .imgwrap {
      width: calc(100% -540px);
    }
  }

}

.top-slide-case {
  width: 1100px;
  margin: 0 auto;
  @include sp {
    margin: 15px 0 0;
    width: 100%;
    padding: 0;
  }
  .slick-list {
    overflow: initial;
  }
  .slick-slide {
    width: 332px;
    margin: 0 25px;
    @include sp {
      margin: 0 10px;
    }
    a {
      display: block;
      transition: all .2s linear;
      &:hover {
        .img {
          img {
            width: 110%;
            @include sp {
              width: 100%;
            }
          }
        }
        .u_txt_1 {
          color: #0B5CA3;
          @include sp {
            color: $black;
          }
        }
      }
    }
    .img {
      position: relative;
      display: block;
      height: 187px;
      width: 100%;
      overflow: hidden;
      @include sp {
        padding: 57% 0 0;
        height: auto;
      }
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      position: absolute;
      transform: translate(-50%,-50%);
      transition: all .2s linear;
      backface-visibility: hidden;
	    -webkit-backface-visibility: hidden;
      @include sp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(0);
      }
    }
  }
  .u_txt_1 {
    font-family: $min;
    color: $black;
    transition: all .2s linear;
  }

  .slick-arrow {
    color: transparent;
    width: 30px;
    height: 30px;
    border: 1px solid #0B5CA3;
    position: relative;
    @include sp {
      width: 40px;
      height: 40px;
    }
    &.slick-next,
    &.slick-prev,{
      transition: all .2s linear;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 33%;
        transform-origin: center;
        transform: translateY(-50%);
        @include sp {
          width: 12px;
          height: 12px;
        }
      }
      &:hover {
        background: #0B5CA3;
      }
    }
    &.slick-prev {
      position: absolute;
      top: -75px;
      right: 43px;
      @include sp {
        top: inherit;
        bottom: -55px;
        transform: translateX(-50%);
        left: calc(50% - 30px);
      }
      &:before {
        left: 11px;
        border-bottom: 1px solid #0B5CA3;
        border-left: 1px solid #0B5CA3;
        transform: rotate(45deg);
        transition: all .2s linear;
        @include sp {
          left: 15px;
        }
      }
      &:hover {
        &:before {
          border-bottom: 1px solid$white;
          border-left: 1px solid $white;
        }
      }
    }
    &.slick-next {
      position: absolute;
      top: -75px;
      right: 3px;
      @include sp {
        top: inherit;
        bottom: -55px;
        transform: translateX(-50%);
        left: calc(50% + 30px);
      }
      &:before {
        right: 11px;
        border-bottom: 1px solid #0B5CA3;
        border-right: 1px solid #0B5CA3;
        transform: rotate(-45deg);
        transition: all .2s linear;
        @include sp {
          right: 15px;
        }
      }
      &:hover {
        &:before {
          border-bottom: 1px solid $white;
          border-right: 1px solid $white;
        }
      }
    }
  }

  .slick-counter {
    position: absolute;
    top: -69px;
    right: 102px;
    font-size: 18px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
    @include sp {
      display: none;
    }
    .current {
      font-weight: bold;
      margin: 0 60px 0 0;
      position: relative;
      &:after {
        content:'';
        width: 47px;
        height: 1px;
        background: #0B5CA3;
        position: absolute;
        top: 8px;
        left: 17px;
      }
    }
    .total {
      color: #B7B7B7;
    }
  }

  .slick-dots {
    width: 84%;
    margin: 20px 8% 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #B7B7B7;
      position: absolute;
      top: 2px;
      left: 0;
      z-index: -1;
    }
    li {
      height: 4px;
      width: calc(100% / 8);
      color: transparent;
      &.slick-active {
        background: #0B5CA3;
      }
    }
  }

  .tags {
    position: absolute;
    background: #0B5CA3;
    bottom: 10px;
    left: 10px;
    color: #fff;
    padding: 5px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
  }

  .btnwrap {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin: 40px 0 0;
    @include sp {
      width: 100%;
      padding: 0 4.6875%;
      margin: 85px 0 0;
    }
  }
}

.flexend {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-end;
  .top-slide-project {
    width: calc( 100% - (100% - 1100px) / 2 );
    overflow: hidden;
    @include sp {
      width: 100%;
    }
    .slick-slider {
      @include sp {
        padding: 0 0 55px 0;
      }
    }
    .slick-list {
      padding: 30px 0 10px;
      height: 500px;
      @include sp {
        height: auto;
        padding: 30px 0 0;
      }
    }
    .slick-arrow {
      color: transparent;
      width: 30px;
      height: 30px;
      border: 1px solid #0B5CA3;
      position: relative;
      transition: all .2s linear;
      @include sp {
        width: 40px;
        height: 40px;
      }
      &:hover {
        background: #0B5CA4;
        @include sp {
          background: #fff;
        }
      }
      &.slick-next,
      &.slick-prev,{
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: 33%;
          transform-origin: center;
          transform: translateY(-50%);
          @include sp {
            width: 12px;
            height: 12px;
          }
        }
      }
      &.slick-prev {
        position: absolute;
        top: 30px;
        left: 1030px;
        z-index: 1;
        @include sp {
          top: inherit;
          bottom: 0;
          transform: translateX(-50%);
          left: calc(50% - 30px);
        }
        &:hover {
          &:before {
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            @include sp {
              border-bottom: 1px solid #0B5CA3;
              border-left: 1px solid #0B5CA3;
            }
          }
        }
        &:before {
          left: 11px;
          border-bottom: 1px solid #0B5CA3;
          border-left: 1px solid #0B5CA3;
          transform: rotate(45deg);
          @include sp {
            left: 15px;
          }
        }
      }
      &.slick-next {
        position: absolute;
        top: 30px;
        left: 1070px;
        @include sp {
          top: inherit;
          bottom: 0;
          transform: translateX(-50%);
          left: calc(50% + 30px);
        }
        &:hover {
          &:before {
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            @include sp {
              border-bottom: 1px solid #0B5CA3;
              border-right: 1px solid #0B5CA3;
            }
          }
        }
        &:before {
          right: 11px;
          border-bottom: 1px solid #0B5CA3;
          border-right: 1px solid #0B5CA3;
          transform: rotate(-45deg);
          @include sp {
            right: 15px;
          }
        }
      }
    }
    .slick-counter {
      position: absolute;
      top: 35px;
      left: 920px;
      font-size: 18px;
      font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
      .current02 {
        font-weight: bold;
        margin: 0 60px 0 0;
        position: relative;
        &:after {
          content:'';
          width: 47px;
          height: 1px;
          background: #0B5CA3;
          position: absolute;
          top: 8px;
          left: 17px;
        }
      }
      .total02 {
        color: #B7B7B7;
      }
    }
    .c_list_3 {
      position: relative;
      .now-counter {
        position: absolute;
        top: -8px;
        left: 570px;
        font-weight: bold;
        font-size: 65px;
        font-family: $min;
        letter-spacing: .05em;
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #1163B2;
        -webkit-text-fill-color: transparent;
        transition: all .2s linear;
        &._active {
          color: #1163B2;
          -webkit-text-fill-color: #1163B2;
        }
        @include sp {
          top: -3px;
          left: 80vw;
          font-size: 55px;
        }
      }
    }
    .slick-slide {
      width: 610px;
      transition: .3s ease;
      transform: scale(.6);
      transform-origin: center center;
      @include sp {
        width: 90vw;
        padding: 0 0 0 5vw;
      }
      .img {
        display: block;
        position: relative;
        @include sp {
          padding: 56% 0 0;
        }
        .tag {
          position: absolute;
          background: #0B5CA3;
          bottom: 10px;
          left: 10px;
          color: #fff;
          padding: 5px;
          white-space: nowrap;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .img {
        overflow: hidden;
      }
      img {
        width: 100%;
        @include sp {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .u_txt_1 {
        color: $black;
        font-family: $min;
      }
      .u_txt_12 {
        color: $black;
        display: none;
      }
      &.slick-current {
        &+.slick-slide {
          transform: translate(-40px, 12px) scale(.6)!important;
          @include sp {
            transform: translate(-15vw, 12px) scale(.6)!important;
          }
        }
        .u_txt_1 {
          display: block;
          margin: 10px 0 5px;
        }
        .u_txt_12 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      &+.slick-slide {
        &:not(.slick-current) {
          transform: translate(-200px, 12px) scale(.6);
          @include sp {
            overflow: hidden;
            transform: translate(-100px, 12px) scale(.6);
          }
          .txtwrap {
            @include sp {
              white-space: nowrap;
            }
          }
          .u_txt_1 {
            font-size: 30px;
            display: block;
            margin: 10px 0 5px;
          }
          .tag {
            font-size: 21px;
          }
          img {
            object-fit: cover;
            width: 610px;
            height: 345px;
            @include sp {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .slick-current {
      transform: scale(1);
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      img {
        object-fit: cover;
        width: 610px;
        height: 345px;
        transition: all .2s linear;
        @include sp {
          width: 100%;
          height: 100%;
        }
      }
    }
    .u_txt_12 {
      line-height: 1.8;
    }
  }
  .slick-dots {
    width: calc( 100% - 15vw - 8%);
    margin: 20px 15vw 0 8%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    position: relative;
    @include sp {
      margin: 20px 15vw 0 8%;
    }
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #B7B7B7;
      position: absolute;
      top: 2px;
      left: 0;
      z-index: -1;
    }
    li {
      height: 4px;
      width: calc(100% / 6);
      color: transparent;
      &.slick-active {
        background: #0B5CA3;
      }
    }
  }
  .btnwrap {
    width: 1100px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    margin: -60px 0 1px;
    @include sp {
      width: 100%;
      padding: 0 4.6875%;
      margin: 30px 0 0;
    }
  }
}

.doubleimgwrap {
  width: 1100px;
  margin: 30px auto 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  @include sp {
    width: 100%;
    margin: 20px auto 0;
    padding: 0 4.6875%;
    flex-wrap: wrap;
  }
  a {
    width: 520px;
    box-shadow: 0px 0px 18px -6px rgba(0, 0, 0, 0.3);
    transition: all .2s linear;
    &:not(:first-of-type) {
      @include sp {
        margin: 30px 0 0;
      }
    }
    @include sp {
      width: 100%;
    }
    &:hover {
      opacity: .7;
      transform: translateY(10px);
      box-shadow: 0px 0px 18px -6px rgba(0, 0, 0, 0);
      @include sp {
        opacity: 1;
        transform: translateY(0);
        box-shadow: 0px 0px 18px -6px rgba(0, 0, 0, 0.3);
      }
    }
    .img {
      overflow: hidden;
      img {
        width: 100%;
        transition: all .2s linear;
      }
    }
    .txt {
      padding: 30px 20px;
      font-size: 26px;
      font-family: $min;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      color: $black;
      position: relative;
      align-items:center;
      @include sp {
        font-size: 18px;
        padding: 15px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 41%;
        right: 30px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #0B5CA3;
        border-right: 2px solid #0B5CA3;
        transform: rotate(45deg);
        @include sp {
          border-top: 1px solid #0B5CA3;
          border-right: 1px solid #0B5CA3;
          width: 10px;
          height: 10px;
        }
      }
      p {
        margin: 0 0 0 10px;
      }
    }
  }
}

.info {
  margin: 100px 0 0;
  @include sp {
    margin: 80px 0 0;
    position: relative;
    &:before {
      content: '+';
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;
      color: #B7B7B7;
    }
  }
  >.inner {
    padding: 60px 50px;
    background: url(../img/p_top/bg_texture.png);
    background-repeat: repeat;
    @include sp {
      padding: 60px 4.6875%;
    }
  }
  &-ttl {
    font-size: 30px;
    font-family: $min;
    font-weight: bold;
    @include sp {
      font-size: 24px;
    }
  }

  .switchwrap {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    margin: -33px 0 0;
    @include sp {
      margin: 20px 0 0;
    }
  }

  .switch {
    display: -webkit-inline-flex;
    display: -ms-inline-flex;
    display: inline-flex;
    border-right : 1px solid #0B5CA4;
    border-left : 1px solid #0B5CA4;
    @include sp {
      width: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }
    li {
      border-top: 1px solid #0B5CA4;
      border-bottom: 1px solid #0B5CA4;
      background: $white;
      cursor: pointer;
      width: 168px;
      height: 30px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      @include sp {
        width: calc(100% / 3);
        height: 40px;
      }
      @include pc {
        transition: color .2s, background-color .2s;
      }
      &:not(:first-child) {
        border-left: 1px solid #0B5CA4;
      }
      &._active {
        background: #0B5CA4;
        color: #fff;
      }
      &:hover {
        @include pc {
          background: #0B5CA4;
          color: #fff;
        }
      }
    }
  }

  ._seminar,
  ._tour {
    display: none;
  }

  .newslists {
    margin: 30px 0 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include sp {
      margin: 10px 0 0;
    }
    &-item {
      width: calc(50% - 15px);
      @include sp {
        width: 100%;
        &:nth-child(n + 2){
          border-top: 1px solid #E5E5E5;
        }
      }
      &-link {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        padding: 20px 25px 20px 10px;
        position: relative;
        transition: all .2s linear;
        &:hover {
          opacity: .7;
          @include sp {
            opacity: 1;
          }
        }
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-top: 1px solid #0B5CA4;
          border-right: 1px solid #0B5CA4;
          transform-origin: center;
          transform: rotate(45deg) translateX(-50%);
          position: absolute;
          right: 10px;
          top: 50%;
        }
        @include sp {
          padding: 20px 0;
        }
      }
      &-img {
        width: 80px;
        height: 80px;
        @include sp {
          width: 75px;
          height: 75px;
        }
        >img {
          object-fit: cover;
          width: 80px;
          height: 80px;
          @include sp {
            width: 75px;
            height: 75px;
          }
        }
      }
      &-txt {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        padding: 0 0 0 10px;
        @include sp {
          padding: 0 30px 0 10px;
        }
        .tags {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
          .tag {
            display: inline-block;
            color: $black;
            background: #EBDD2D;
            font-size: 11px;
            padding: 7px 7px 6px;
            font-weight: bold;
          }
          .day {
            padding: 0 0 0 18px;
            font-weight: bold;
            font-size: 14px;
            color: $black;
          }
        }
        .u_txt_2 {
          margin: 10px 0 0;
          color: $black;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      &:nth-child( n + 3 ) {
        border-top: 2px solid #E5E5E5;
      }
    }
  }

  .btnwrap {
    margin: 20px 0 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    .c_btn_1 {
      height: 50px;
    }
  }

}

.bnrbox1 {
  margin: 80px 0 0;
  position: relative;
  @include sp {
    padding: 0 4.6875%;
    &:before {
      content: '+';
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;
      color: #B7B7B7;
    }
  }
  .inner {
    .bnr {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      @include sp {
        flex-wrap: wrap;
      }
    }
    a {
      width: 530px;
      transition: all .2s linear;
      @include sp {
        width: 100%;
        &:not(:first-of-type) {
          margin: 15px 0 0;
        }
      }
      &:hover {
        @include pc {
          opacity: .7;
        }
      }
      >img {
        max-width: 100%;
      }
    }
  }
}

.bnrslidewrap {
  background: #f1f1f1;
  padding: 20px 0;
  margin: 20px 0 0;
  @include sp {
    margin: 40px 0 0;
  }
}
.bnrslide {
  width: 980px;
  margin: 0 auto;
  @include sp {
    width: 100%;
  }
  .slick-list {
    margin: 0 10px;
    @include sp {
      margin: 0;
    }
  }
  .slick-slide {
    margin: 0 10px;
    width: 220px;
    img {
      max-width: 100%;
    }
  }
  .slick-arrow {
    color: transparent;
    width: 30px;
    height: 30px;
    background: #0B5CA4;
    position: relative;
    @include sp {
      z-index: 1;
      width: 40px;
      height: 40px;
    }
    &.slick-next,
    &.slick-prev,{
      transition: all .2s linear;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 33%;
        transform-origin: center;
        transform: translateY(-50%);
        @include sp {
          width: 12px;
          height: 12px;
        }
      }
      &:hover {
        background: #fff;
        outline: 1px solid #0B5CA4;
        @include sp {
          background: #0B5CA4;
        }
      }
    }
    &.slick-prev {
      position: absolute;
      top: 15px;
      left: -60px;
      @include sp {
        top: 10px;
        left: 0;
      }
      &:hover {
        &:before {
          border-bottom: 1px solid #0B5CA4;
          border-left: 1px solid #0B5CA4;
          @include sp {
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
          }
        }
      }
      &:before {
        left: 12px;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        transform: rotate(45deg);
        @include sp {
          left: 15px;
        }
      }
    }
    &.slick-next {
      position: absolute;
      top: 15px;
      right: -60px;
      @include sp {
        top: 10px;
        right: 0;
      }
      &:hover {
        &:before {
          border-bottom: 1px solid #0B5CA4;
          border-right: 1px solid #0B5CA4;
          @include sp {
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
          }
        }
      }
      &:before {
        right: 12px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(-45deg);
        @include sp {
          right: 15px;
        }
      }
    }
  }
}
